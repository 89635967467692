import http from 'utility/fetch'

//key config
export const createConfigurationApi = (data) => http.post('/admin/createConfiguration', data);

export const updateConfigurationApi = (data) => http.post('/admin/updateConfiguration', data);

export const getConfigurationDetailsApi = (data) => http.post('/admin/getConfigurationDetails', data);

export const getAllConfigurationsApi = (data) => http.post('/admin/getAllConfigurations', data);

export const getAllActiveCountryCodesApi = (data) => http.post('/admin/getAllActiveCountryCodes', data);

export const getNotification = (data) => http.post('/admin/getNotificationById', data);

export const getDashboardApi = (data) => http.post('/admin/dashboard', data);

export const updateNotificationReadAllStatusApi = (data) => http.post('/admin/updateNotificationReadAllStatus', data);

export const updateNotificationReadStatusApi = (data) => http.post('/admin/updateNotificationReadStatus', data);

export const checkAndEnableAdminChatNotificationApi = (data) => http.post('/admin/checkAndEnableAdminChatNotification', data);

export const addAdminChatMessageApi = (data) => http.post('/chat/addAdminChatMessage', data);

export const getreportdetailsonactivities = (data) => http.post('/admin/getReportingDetailsOnActivities', data);

export const getreportActivitiesFilters = (data) => http.post('/admin/getReportingActivitiesFilters', data);

export const getreportdetailsondesigners = (data) => http.post('/admin/getReportingDetailsOnDesigners', data);

export const getreportSalesOrder = (data) => http.post('/admin/salesOrderReport', data);

export const getSalesOrderExport = (data) => http.post('/admin/salesOrderReportExportRequest', data)

export const getSalesOrderDetail = (data) => http.post('/admin/salesOrderDetailReport', data)

export const getSalesOrderExpandDetail = (data) => http.post('/admin/salesOrderReportExpand', data)

export const getSalesReportDetails = (data) => http.post('/admin/orderSummaryReport', data);

// upload file
export const uploadFileApi = (data) => http.post('/admin/uploadProfilePic', data);

export const getFollowUpReportData = (data) => http.post('/admin/followUpReportSummary', data)

export const getReminderReport = (data) => http.post('/admin/reminderReportSummary', data)

export const getReminderAllCities = () => http.post('/admin/getAllCity')

export const getReminderCoach = (data) => http.post('/admin/getAreaManager', data)

export const getReminderRemindTo = (data) => http.post('/admin/getRemindToUser', data)

export const getReminderSummaryExport = (data) => http.post('/admin/reminderReportSummaryExportRequest', data)

export const getStageWiseOrderLost = (data) => http.post('/admin/getStageWiseOrderLost', data)


// designer apis
export const createOfflineCustomer = (data) => http.post('/designer/createOfflineCusromer', data)

export const getAllCustomersForDesigner = (data) => http.post('/designer/getAllCustomersForDesigner', data)

export const createOfflineOrder = (data) => http.post('/designer/createOfflineOrderForDesigner', data)

export const createRemainderFromDesigner = (data) => http.post('/designer/createReminder', data)

export const getNonAssignedAreaManagers = () => http.post('/admin/listAllActiveAreaManagerForBranch');

export const getAllWarehouseForDesingers = () => http.post('/designer/getAllWarehouse');

export const applyCouponForContract = (data) => http.post('/designer/applyDesignerCouponCode',data);

export const generateContractFromDesigner = (data) => http.post('designer/generateOfflineContractDocumentByDesigner',data);

// branches API
export const createBranchFromAdmin = (data) => http.post('/admin/createBranch', data)

export const getAllBranchFromAdmin = (data) => http.post('/admin/getAllBranch', data)

export const getBranchByIdFromAdmin = (data) => http.post('/admin/getBranchById', data)

export const updateDesignerPlatform = (data) => http.post('/admin/updateDesignerWorkPlatform', data)

// brands
export const createBrandFromAdmin = (data) => http.post('/admin/createBrand', data)

export const updateBrandFromAdmin = (data) => http.post('/admin/updateBrand', data)

export const deleteBrandFromAdmin = (data) => http.post('/admin/deleteBrand', data)

export const getAllBrandFromAdmin = (data) => http.post('/admin/getAllBrand', data)

export const getBrandByIdFromAdmin = (data) => http.post('/admin/getBrandById', data)

export const getBrandListFromAdmin = (data) => http.post('/admin/getListAllBrand', data)

export const getKitchenModelListFromAdmin = (data) => http.post('admin/listAllActiveKitchenModelByBrandId', data)

// appliance order report
export const getAllApplianceReport = (data) => http.post('/admin/applianceBreakdownReport', data)

// service ticket APIs
export const getServiceTicketList = (data) => http.post('/admin/getCustomerService', data)

export const getServiceTicketDetails = (data) => http.post('/admin/getCompleteCustomerServiceDeatils', data)

export const addCommentInServiceTicket = (data) => http.post('/admin/postCustomerOrderChat', data)


