/* eslint-disable camelcase */
import http from 'utility/fetch'

export const getAllDesignerApi = (data) => http.post('/admin/getAllDesigner', data);

export const getAllFitterApi = (data) => http.post('/admin/getAllFitter', data);

export const getAllDeliveryPersonApi = (data) => http.post('/admin/getAllDeliveryPerson', data);

export const getAllAreaManagerApi = (data) => http.post('/admin/getAllAreaManager', data);

export const getAllCustomerCareApi = (data) => http.post('/admin/getAllCustomerCare', data);

export const getAllCmsPersonsApi = (data) => http.post('/admin/getAllCmsPersons', data);

export const getAllAdminsApi = (data) => http.post('/admin/getAllAdmins', data);

export const addDesigner = (data) => http.post('/admin/createDesigner', data);

export const updateDesigner = (data) => http.post('/admin/updateDesignerProfileDetailsAdmin', data);

export const addFitter = (data) => http.post('/admin/createFitter', data);

export const updateFitter = (data) => http.post('/admin/updateFitterProfileDetailsAdmin', data);

export const addDeliveryPerson = (data) => http.post('/admin/createDeliveryPerson', data);

export const updateDeliveryPerson = (data) => http.post('/admin/updateDeliveryProfileDetailsAdmin', data);

export const addAreaManager = (data) => http.post('/admin/createAreaManager', data);

export const updateAreaManager = (data) => http.post('/admin/updateAreaManager', data);

export const addCustomerCare = (data) => http.post('/admin/createCustomerCare', data);

export const addCmsPerson = (data) => http.post('/admin/createCmsPerson', data);

export const addAdmin = (data) => http.post('/admin/createAdmin', data);

export const updateCustomerCare = (data) => http.post('/admin/updateCustomerCare', data);

export const deleteDesigner = (id) => http.post('/admin/deleteDesigner', { designer_id: id });

export const deleteFitter = (id) => http.post('/admin/deleteFitter', { fitter_id: id });

export const deleteDeliveryPerson = (id) => http.post('/admin/deleteDeliveryPerson', { delivery_id: id });

export const deleteAreaManager = (id) => http.post('/admin/deleteAreaManager', { admin_id: id });

export const deleteCustomerCare = (id) => http.post('/admin/deleteCustomerCare', { admin_id: id });

export const statusChangeDesigner = (id, status) => http.post('/admin/updateDesignerStatus', { designer_id: id, status });

export const holdStatusChangeDesigner = (id, status) => http.post('/admin/updateDesignerHoldStatus', {id: id, is_on_hold: status});

export const statusChangeFitter = (id, status) => http.post('/admin/updateFitterStatus', { fitter_id: id, status });

export const statusChangeDeliveryPerson = (id, status) => http.post('/admin/updateDeliveryStatus', { delivery_id: id, status });

export const statusChangeAreaManager = (id, status) => http.post('/admin/updateAreaManagerStatus', { admin_id: id, status });

export const statusChangeCustomerCare = (id, status) => http.post('/admin/updateCustomerCareStatus', { admin_id: id, status });

export const getDesignerProfileAPi = (id) => http.post('/admin/getDesignerProfileDetailsAdmin', { id });

export const getFitterProfileApi = (id) => http.post('/admin/getFitterProfileDetailsAdmin', { id });

export const getDeliveryPersonProfileApi = (id) => http.post('/admin/getDeliveryProfileDetailsAdmin', { id });

export const getAreaManagerProfileApi = (id) => http.post('/admin/getAreaManagerProfileDetailsAdmin', { id });

export const getCustomerCareProfileApi = (id) => http.post('/admin/getCustomerCareProfileDetailsAdmin', { id });

export const getAllActiveDesigner = () => http.post('/admin/listAllActiveDesigner');

export const getAllActiveFitter = () => http.post('/admin/listAllActiveFitter');

export const getAllActiveDeliveryPerson = () => http.post('/admin/listAllActiveDeliveryPerson');

export const bulkAssignUserToAreaManager = (data) => http.post('/admin/updateDesignerFitterDailveryAgentListToAreaManager', data);

export const approveOrRejectDesignerVideoOrImagesApi = (data) => http.post('/admin/approveOrRejectDesignerVideoOrImages', data);

export const confirmEarningsStatusApi = (data) => http.post('/admin/confirmEarningsStatus', data);

export const createEarningsApi = (data) => http.post('/admin/createEarnings', data);

export const getCustomerOrderListWithPendingStatusApi = () => http.post('/admin/getCustomerOrderListWithPendingStatus');

export const updateDesignerWorksApi = (data) => http.post('/admin/updateDesignerWorks', data);

export const getCustomerOrderListBasedOnPersonLocationApi = (data) => http.post('/admin/getCustomerOrderListBasedOnPersonLocation', data);

export const getPersonListbasedOnAreaManagerLocationApi = (data) => http.post('/admin/getPersonListbasedOnAreaManagerLocation', data);

export const getActiveAreaManager = () => http.post('/admin/listAllActiveAreaManager');

export const deleteAssignedPersonFromAreaManager = (data) => http.post('/admin/deleteAssignedPersonFromAreaManager', data)

export const getCustomerByMobileNumber = (data) => http.post('/designer/getCustomerByMobileNumber', data)


// delete || update status of admin
export const deleteAdmin = (id) => http.post('/admin/deleteAdminAcount', {admin_id:id});

export const updateAdminStatus = (id, status) => http.post('/admin/deactivateAdminAcount', {admin_id:id,status});