import axios from 'axios';

import http from 'utility/fetch';

export const uploadFile = async (file, fileFor) => {
    let uploadedUrl = "";
    // let imgexte = params.file.name.substr(params.file.name.lastIndexOf('.') + 1);
    try {
        const UploadRequest = await http.post("admin/uploadProfilePic",
            {
                "file": `${fileFor}.${file.type.split("/").pop()}`
            },
        )
        if (UploadRequest.data.status_code === 200) {
            var formData = new FormData();
            for (let key in UploadRequest.data.response.fields) {
                formData.append(key, UploadRequest.data.response.fields[key]);
            }
            formData.append("file", file);
            await axios.post(UploadRequest.data.response.url, formData);
            uploadedUrl = UploadRequest.data.response.url + '/' + UploadRequest.data.response.fields.key;
            return uploadedUrl;
        }
    }
    catch (e) {
        return undefined;
    }
}
 