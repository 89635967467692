import React from "react"
import * as Icon from "react-feather"
import reminder from "../assets/icons/svg/reminder.svg"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["super","admin", "area_manager", "designer", "admin_read_only"],
    navLink: "/dashboard",
    activeLink: "/dashboard"
  },
  {
    id: "designer-profile",
    title: "My Profile",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/profile",
    activeLink: "/designer/profile"
  },
  {
    id: "designer-order",
    title: "My Orders",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/order?page=0&size=10",
    activeLink: "/designer/order"
  },
  {
    id: "designer-earn",
    title: "My Earnings",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/earning?page=0&size=10&status=completed",
    activeLink: "/designer/earning"
  },
  // {
  //   id: "designer-draft",
  //   title: "Draft",
  //   type: "item",
  //   icon: <Icon.Package size={20} />,
  //   permissions: ["designer"],
  //   navLink: "/designer/draft",
  //   activeLink: "/designer/draft"
  // },
  {
    type: "groupHeader",
    groupTitle: "Manage Users",
    permissions: ["super","admin", "area_manager"],
  },
  {
    id: "designers",
    title: "Designers",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super","admin", "area_manager"],
    navLink: "/manage_users/designer?page=0&size=10",
    activeLink: "/manage_users/designer"
  },
  {
    id: "fitters",
    title: "Fitters",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super","admin", "area_manager"],
    navLink: "/manage_users/fitter?page=0&size=10",
    activeLink: "/manage_users/fitter"
  },
  {
    id: "deliverers",
    title: "Delivery Agents",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super","admin", "area_manager"],
    navLink: "/manage_users/delivery-agent?page=0&size=10",
    activeLink: "/manage_users/delivery-agent"
  },
  {
    id: "areamgrs",
    title: "Coaches",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super","admin", "editor"],
    navLink: "/manage_users/area-manager?page=0&size=10",
    activeLink: "/manage_users/area-manager"
  },
  {
    id: "branches",
    title: "Branches",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super","admin"],
    navLink: "/manage_users/branches?page=0&size=10",
    activeLink: "/manage_users/branches"
  },
  {
    id: "brands",
    title: "Manufacturer",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/brands?page=0&size=10",
    activeLink: "/manage_users/brands"
  },
  {
    id: "customercare",
    title: "Customer Care",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/customer_care?page=0&size=10",
    activeLink: "/manage_users/customer_care"
  },
// NEW
  {
    id: "cmsperson",
    title: "CMS Persons",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/cms_person?page=0&size=10",
    activeLink: "/manage_users/cms_person"
  },
  {
    id: "admin",
    title: "Admins",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "editor"],
    navLink: "/manage_users/admin?page=0&size=10",
    activeLink: "/manage_users/admin"
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Orders",
    permissions: ["super","admin", "area_manager", "customer_care"],
  },
  {
    id: "order",
    title: "Orders",
    type: "collapse",
    icon: <Icon.Package size={20} />,
    permissions: ["super","admin", "area_manager", "customer_care"],
    children: [
      {
        id: "kitchenSet",
        title: "Kitchen Set",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin","area_manager","customer_care"],
        navLink: "/manage_orders/orders?page=0&size=10",
        activeLink: "/manage_orders/orders",
      },
      {
        id: "otherOrders",
        title: "Other Orders",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin","area_manager","customer_care"],
        navLink: "/manage_other_orders/otherorders?page=0&size=10",
        activeLink: "/manage_other_orders/otherorders"
      }
    ]
  },
  {
    id: "customer",
    title: "Customers",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["super","admin", "area_manager", "customer_care","designer"],
    navLink: "/manage_orders/customers?page=0&size=10",
    activeLink: "/manage_orders/customers"
  },
  {
    id: "pendingorders",
    title: "Pending Orders",
    type: "collapse",
    icon: <Icon.Package size={20} />,
    permissions: ["super","admin", "area_manager", "customer_care"],
    children: [
      {
        id: "pendingmultipleordersrequests",
        title: "Pending Multiple Orders Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin", "area_manager", "customer_care"],
        navLink: "/manage_orders/pending_multiple_orders_requests?page=0&size=10",
        activeLink: "/manage_orders/pending_multiple_orders_requests"
      },
      {
        id: "pendingunassignedordersrequests",
        title: "Unassigned Orders Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin","area_manager","customer_care"],
        navLink: "/manage_orders/unassigned_orders?page=0&size=10",
        activeLink: "/manage_orders/unassigned_orders",
      },
    ]
  },
  // service tikets
  {
    id: "service_tikets",
    title: "Service Tickets",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["super","admin","area_manager"],
    navLink: "/manage_orders/manage_service_tickets?page=0&size=10",
    activeLink: "/manage_orders/manage_service_tickets"
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Products",
    permissions: ["super","cms_person"],
  },
  {
    id: "kitchen",
    title: "Kitchen",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor", "cms_person"],
    children: [
      {
        id: "kCategories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","cms_person"],
        navLink: "/manage_products/kitchen/categories",
        activeLink: "/manage_products/kitchen/categories"
      },
      {
        id: "kitchenShape",
        title: "Shapes",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen/shapes",
        activeLink: "/manage_products/kitchen/shapes"
      },
      {
        id: "kitchenModel",
        title: "Models",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","cms_person"],
        navLink: "/manage_products/kitchen/models",
        activeLink: "/manage_products/kitchen/models"
      },
      {
        id: "kitchenDimension",
        title: "Dimensions",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","cms_person"],
        navLink: "/manage_products/kitchen/dimensions",
        activeLink: "/manage_products/kitchen/dimensions"
      },
      {
        id: "kitchenProd",
        title: "Kitchen",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","cms_person"],
        navLink: "/manage_products/kitchen",
        activeLink: "/manage_products/kitchen"
      }
    ]
  },
  //  {
  //   id: "products",
  //   title: "Products",
  //   type: "collapse",
  //   icon: <Icon.List size={20} />,
  //   permissions: ["super", "editor"],
  //   children: []
  //  },
  { 
    id: "products",
    title: "Products",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor"],
    children: [
      {
        id: "kCategories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor"],
        navLink: "/manage_products/appliances/Acategories",
        activeLink: "/manage_products/appliances/Acategories"
      },
      {
        id: "Manufacturer",
        title: "Manufacturer",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/manufacturer",
        activeLink: "/manage_products/appliances/Manufacturer"
      },
      {
        id: "kGroup",
        title: "Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/group",
        activeLink: "/manage_products/appliances/group"
      },
      {
        id: "kSubGroup",
        title: "Sub-Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/sub-group",
        activeLink: "/manage_products/appliances/sub-group"
      },
      {
        id: "kItemType",
        title: "Item-Type",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/item-type",
        activeLink: "/manage_products/appliances/item-type"
      },
      {
        id: "kProducts",
        title: "Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/products",
        activeLink: "/manage_products/appliances/products"
      }
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "Contents",
    permissions: ["super","cms_person"],
  },
  {
    id: "homePage",
    title: "HomePage",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super","editor","cms_person"],
    children: [
      {
        id: "ideaBehindNiuli",
        title: "Idea Behind Niuli",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_homepage/idea-behind-niuli",
        activeLink: "/manage_homepage/idea-behind-niuli"
      },
      {
        id: "whyNiuli",
        title: "Why Niuli",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_homepage/why-niuli",
        activeLink: "/manage_homepage/why-niuli"
      },
      {
        id: "howNiuliWorks",
        title: "How Niuli Works",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_homepage/how-niuli-works",
        activeLink: "/manage_homepage/how-niuli-works"
      },
      {
        id: "howNiuliIsBetter",
        title: "How Niuli Is Better",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_homepage/how-niuli-is-better",
        activeLink: "/manage_homepage/how-niuli-is-better"
      },
      {
        id: "findOutWhatYouLike",
        title: "Find Out What You Like",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_homepage/find-out-what-you-like",
        activeLink: "/manage_homepage/find-out-what-you-like"
      }
    ]
  },
  {
    id: "promotion",
    title: "Promotions",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor","cms_person"],
    children: [
      {
        id: "Banner",
        title: "Banner",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor","cms_person"],
        navLink: "/manage_promotion/banner",
        activeLink: "/manage_promotion/banner"
      },
      {
        id: "coupons",
        title: "Coupons",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","cms_person"],
        navLink: "/manage_promotion/coupons",
        activeLink: "/manage_promotion/coupons"
      }
    ]
  },
  {
    id: "key_config",
    title: "Key Configurations",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor"],
    navLink: "/manage/keys",
    activeLink: "/manage/keys"
  },
  //reports section
  {
    type: "groupHeader",
    groupTitle: "Reports",
    permissions: ["super","admin", "admin_read_only","area_manager"],
  },
  {
    id: "sale",
    title: "Sales",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin"],
    children: [
      {
        id: "designers",
        title: "Designers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin"],
        navLink: "/manage_reports/sales?page=0&size=10",
        activeLink: "/manage_reports/sales"
      },
    ]
  },
  {
    id: "activities",
    title: "Activities",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin"],
    navLink: "/manage_reports/activity",
    activeLink: "/manage_reports/activity"
  },
  {
    id: "emailreport",
    title: "Email Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin", "admin_read_only"],
    navLink: "/email-reports",
    activeLink: "/email-reports"
  },
  {
    id: "salesreport",
    title: "Sales Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin", "area_manager", "admin_read_only"],
    navLink: "/sales-reports?page=0&type=appRegistration",
    activeLink: "/sales-reports"
  },
  {
    id: "followUpreport",
    title: "Follow-Up Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin", "admin_read_only"],
    navLink: "/manage_reports/followUp",
    activeLink: "/manage_reports/followUp"
  },
  {
    id: "salesOrderReport",
    title: "Sales Order Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin", "area_manager", "admin_read_only"],
    navLink: "/manage_reports/salesOrder?page=1&size=10",
    activeLink: "/manage_reports/salesOrder"
  },
  {
    id: "appliancesOrders",
    title: "Appliances Orders",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin","area_manager"],
    navLink: "/manage_reports/appliances-orders",
    activeLink: "/manage_reports/Appliances-orders"
  },
  {
    id: "ReminderReport",
    title: "Reminder Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super","admin", "area_manager", "admin_read_only"],
    navLink: "/manage_reports/reminderReport?page=0&size=10",
    activeLink: "/manage_reports/reminderReport"
  },
  // leads
  // {
  //   type: "groupHeader",
  //   groupTitle: "Manage Leads",
  //   permissions: [ "area_manager"],
  // },
  // {
  //   id: "leads",
  //   title: "Campaign Page",
  //   type: "item",
  //   icon: <Icon.List size={24} />,
  //   permissions: ["area_manager"],
  //   navLink: "/manage_leads/campaign_page?page=1&size=10",
  //   activeLink: "/manage_leads/campaign_page"
  // },

  //reminders section
  {
    type: "groupHeader",
    groupTitle: "Manage Reminders",
    permissions: ["super","admin" , "area_manager"],
  },
  {
    id: "reminders",
    title: "Reminders",
    type: "collapse",
    icon: <img src={reminder} size={20} style={{paddingRight: "10px"}}/>,
    permissions: ["super","admin", "area_manager"],
    children: [
      {
        id: "manualreminders",
        title: "Manual Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin", "area_manager"],
        navLink: "/manage_reminder/reminder?page=0&size=10",
        activeLink: "/manage_reminder/reminder"
      },
      {
        id: "automatedreminders",
        title: "Automated Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_reminder/automated?page=0&size=10",
        activeLink: "/manage_reminder/automated"
      },
      {
        id: "pendingreminders",
        title: "Pending Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super","admin", "area_manager"],
        navLink: "/manage_reminder/pending?page=0&size=10",
        activeLink: "/manage_reminder/pending"
      },
    ]
  },
]

export default navigationConfig
